import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const SignInLanding = React.lazy(() => import('./views/auth/landing'));
const RedirectLanding = React.lazy(() =>
    import('./views/auth/redirect_landing')
);
const Signout = React.lazy(() => import('./views/auth/signout'));
const Register = React.lazy(() => import('./views/auth/register'));
const TOS = React.lazy(() => import('./views/auth/tos'));
const Verify = React.lazy(() => import('./views/auth/verify'));

const LockedEnv = React.lazy(() => import('./views/auth/locked'));

class App extends Component {
    render() {
        return (
            <>
                {[
                    'politicalcomms.com',
                    'politicalcomms-stage.com',
                    // 'localhost',
                ].indexOf(window.location.hostname.replace('www.', '')) >=
                    0 && (
                    <HashRouter>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route
                                    exact
                                    path="/auth/signout"
                                    name="Sign Out"
                                    render={(props) => <Signout {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/locked"
                                    name="Sign Out"
                                    render={(props) => <LockedEnv {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/tos"
                                    name="Terms of Service"
                                    render={(props) => (
                                        <RedirectLanding {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/auth/register"
                                    name="Register"
                                    render={(props) => (
                                        <RedirectLanding {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/auth/verify"
                                    name="Verifying"
                                    render={(props) => (
                                        <RedirectLanding {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/auth/landing"
                                    name="Sign In"
                                    render={(props) => (
                                        <RedirectLanding {...props} />
                                    )}
                                />{' '}
                                <Route
                                    path="/"
                                    name="Redirect"
                                    render={(props) => (
                                        <RedirectLanding {...props} />
                                    )}
                                />
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                )}

                {[
                    'politicalcomms.com',
                    'politicalcomms-stage.com',
                    // 'localhost',
                ].indexOf(window.location.hostname.replace('www.', '')) ===
                    -1 && (
                    <HashRouter>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route
                                    exact
                                    path="/auth/signout"
                                    name="Sign Out"
                                    render={(props) => <Signout {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/locked"
                                    name="Sign Out"
                                    render={(props) => <LockedEnv {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/tos"
                                    name="Terms of Service"
                                    render={(props) => <TOS {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/register"
                                    name="Register"
                                    render={(props) => <Register {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/verify"
                                    name="Verifying"
                                    render={(props) => <Verify {...props} />}
                                />
                                <Route
                                    exact
                                    path="/auth/landing"
                                    name="Sign In"
                                    render={(props) => (
                                        <SignInLanding {...props} />
                                    )}
                                />{' '}
                                <Route
                                    path="/"
                                    name="Redirect"
                                    render={(props) => <TheLayout {...props} />}
                                />
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                )}
            </>
        );
    }
}

export default App;
