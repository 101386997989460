export default class StdReducers {
  constructor(actions) {
    this.col_id = actions.col_id;
    this.initialState = {
      // needed for cache state
      loadedAt: 0,
      // tracking if the state is loading
      isLoading: false,
      // any errors loading all the data
      error: null,
    };
  }
}
