// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('contacts');

actions.registerTypes({
    type: `GET_ALL_CONTACTS_FOR_PROJECT`,
    // usable in reducer and components
    action: `reqAllConvoContacts`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/interaction_instances/${project_id}/contacts`;
    },
    verb: 'POST',
});

actions.registerTypes({
    type: `GET_ONE_CONTACT_FOR_CONVO`,
    // usable in reducer and components
    action: `reqOneConvoContact`,
    endpoint: (props) => {
        const { convo_instance_id, project_id } = props;
        return `/api/v1/interaction_instances/${project_id}/convo_instance/${convo_instance_id}/contacts`;
    },
    verb: 'POST',
});
export default actions;
