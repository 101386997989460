// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('projects');

// actions.registerTypes({
//     type: `PROJECT_UPSERT_OR_UPDATE`,
//     // usable in reducer and components
//     action: `reqUpsertOrUpdate`,
//     endpoint: `/api/v1/projects`,
//     verb: 'POST',
// });

actions.registerTypes({
    type: `PROJECT_UPSERT_RELATED`,
    // usable in reducer and components
    action: `reqUpsertRelated`,
    endpoint: `/api/v1/projects/related`,
    verb: 'POST',
});

actions.registerTypes({
    type: `PROJECT_SEND_ONE_SMS`,
    // usable in reducer and components
    action: `test`,
    endpoint: `/api/v1/projects/test`,
    verb: 'POST',
});

actions.registerTypes({
    type: `CREATE_NEW_PROJECT`,
    // usable in reducer and components
    action: `reqCreateProject`,
    endpoint: `/api/v1/projects/new`,
    verb: 'POST',
});

actions.registerTypes({
    type: `PUT_PROJECT`,
    // usable in reducer and components
    action: `reqPutProject`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}`;
    },
    verb: 'PUT',
});

actions.registerTypes({
    type: `UNSET_PROJECT`,
    // usable in reducer and components
    action: `reqUnsetProject`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/unset_project`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `ACTIVATE_PROJECT`,
    // usable in reducer and components
    action: `reqProjectActivate`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/activate`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `DEACTIVATE_PROJECT`,
    // usable in reducer and components
    action: `reqProjectPause`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/deactivate`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `ARCHIVE_PROJECT`,
    // usable in reducer and components
    action: `archive`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/archive`;
    },
    verb: 'GET',
});
actions.registerTypes({
    type: `ADMIN_ADJUST_PROJECT_LEDGER`,
    // usable in reducer and components
    action: `adminAdjustProjectLedger`,
    endpoint: (props) => {
        // const { project_id } = props;
        return `/api/v1/projects/adminAdjustCost`;
    },
    verb: 'POST',
});

actions.registerTypes({
    type: `PROJECT_SEND_SMS`,
    // usable in reducer and components
    action: `reqProjectSendSMS`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/send_sms_project`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `PROJECT_COUNTS`,
    // usable in reducer and components
    action: `reqCounts`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/get_project_counts`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `ADD_DID_TO_PROJECT`,
    // usable in reducer and components
    action: `reqAddToProject`,
    endpoint: `/api/v1/project_dids/add`,
    verb: 'POST',
});

actions.registerTypes({
    type: `ALL_ARCHIVED_PROJECTS`,
    // usable in reducer and components
    action: `reqAllArchived`,
    endpoint: `/api/v1/projects/get/archived`,
    verb: 'GET',
});

actions.registerTypes({
    type: `RESET_ARCHIVED_PROJECT`,
    // usable in reducer and components
    action: `reqArchiveReset`,
    // endpoint: `/api/v1/projects/reset/archived`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/reset/archived`;
    },
    verb: 'GET',
});

export default actions;
