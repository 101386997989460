import axios from 'axios';

import { baseURL } from './utilities';

// const baseURL =
//     process.env.REACT_APP_ENV === 'production'
//         ? process.env.REACT_APP_COLOR === 'green'
//             ? process.env.REACT_APP_DATA_BANK_BASE_URL_PRODUCTION_GREEN
//             : process.env.REACT_APP_DATA_BANK_BASE_URL_PRODUCTION_BLUE
//         : process.env.REACT_APP_DATA_BANK_BASE_URL_DEVELOPMENT;

const API = axios.create({
    baseURL,
});

// for each api request going out
API.interceptors.request.use(async (config) => {
    // pull the token out of local storage
    const token = localStorage.authToken;
    // if there is no token do nothing
    if (!token) return config;
    // if there is a token, set a header for any request that contains the token
    return {
        ...config,
        headers: {
            common: { token },
            Authorization: `Bearer ${token}`,
        },
    };
});

export default API;
