import StdActions from './StdActions';
import pluralize from 'pluralize';

export default class CrudActions extends StdActions {
    constructor(instanceType) {
        super(instanceType);
        this.col_id = `${pluralize.singular(instanceType)}_id`;

        // All
        this.registerTypes({
            type: `${this.instanceType.toUpperCase()}_ALL`,
            action: `reqAll`,
            verb: 'GET',
            endpoint: `/api/v1/${this.instanceType}`,
        });

        // All
        this.registerTypes({
            type: `${this.instanceType.toUpperCase()}_ALL`,
            action: `reqAllRelated`,
            verb: 'GET',
            endpoint: `/api/v1/${this.instanceType}/related`,
        });

        // One
        this.registerTypes({
            type: `${this.instanceType.toUpperCase()}_ONE`,
            action: `reqOne`,
            endpoint: (props) => {
                const { [this.col_id]: id } = props;
                return `/api/v1/${this.instanceType}/related/${id}`;
            },
        });

        // Create
        this.registerTypes({
            type: `${this.instanceType.toUpperCase()}_UPSERT`,
            action: `reqUpsert`,
            endpoint: `/api/v1/${this.instanceType}`,
            verb: 'POST',
        });

        this.registerTypes({
            type: `${this.instanceType.toUpperCase()}_DISABLE`,
            // usable in reducer and components
            action: `disable`,
            endpoint: `/api/v1/${this.instanceType}`,
            verb: 'DELETE',
        });
    }
}
