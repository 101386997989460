import actions from './actions';
import createReducer from '../helpers/createReducer';
import CrudReducer from '../../classes/CrudReducer';
import actionAssignments from '../../classes/crudActionTypeAssignments';

class ReducerHanlders extends CrudReducer {
    constructor(actions) {
        super(actions);

        // Initial State gets copy/clones with the following additional configurations.
        this.initialState = Object.assign({}, this.initialState, {});
    }
}

const handlerInstance = new ReducerHanlders(actions);

handlerInstance.update_all_success = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: null,
        loadedAt: Date.now(),
        byID: {
            ...state.byID,
            ...action.data.reduce(
                (items, item) => ({
                    // keep the current object
                    ...items,
                    // add the item id as the key and an item object for loading
                    [item[handlerInstance.col_id]]: {
                        data: {
                            ...state.byID[handlerInstance.col_id].data,
                            ...item,
                        },
                        isLoading: false,
                        loadedAt: Date.now(),
                        error: null,
                    },
                }),
                {}
            ),
        },
        allIDs: [
            ...new Set([
                ...state.allIDs,
                ...action.data.map((item) => item[handlerInstance.col_id]),
            ]),
        ],
    };
};

const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:
    // [actions.types.GET_ALL_INTERATIONS_FOR_CONVERSATION_SUCCESS]:
    //     handlerInstance.all_clear_success,
    // [actions.types.GET_ALL_INTERATIONS_FOR_CONVERSATION_FAILED]:
    //     handlerInstance.all_failed,

    [actions.types.SEND_NEW_MSG_TO_CONVO_SUCCESS]:
        handlerInstance.all_clear_success,
    [actions.types.SEND_NEW_MSG_TO_CONVO_FAILED]: handlerInstance.all_failed,

    // [actions.types.STRIPE_REMOVE_CARD_SUCCESS]: handlerInstance.one_success,
    // [actions.types.STRIPE_REMOVE_CARD_FAILED]: handlerInstance.one_failed,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
