export default class StdActions {
    constructor(instanceType) {
        this.instanceType = instanceType;
        this.types = {};
        this.type = {};

        // this.registerTypes(instanceType);
        // cache data for 5 minutes
        this.CACHE_TIME = 1000 * 60 * 5;
    }

    registerTypes(props) {
        const { type, action, endpoint = undefined, verb, headers } = props;
        this.types[`${type.toUpperCase()}`] = `${type.toUpperCase()}`;
        this.types[
            `${type.toUpperCase()}_REQUESTED`
        ] = `${type.toUpperCase()}_REQUESTED`;
        this.types[
            `${type.toUpperCase()}_SUCCESS`
        ] = `${type.toUpperCase()}_SUCCESS`;
        this.types[
            `${type.toUpperCase()}_FAILED`
        ] = `${type.toUpperCase()}_FAILED`;
        this.types[`${type.toUpperCase()}_SET`] = `${type.toUpperCase()}_SET`;

        this[action] = (payload) => {
            return this.httpAction({
                type: this.types[`${type.toUpperCase()}`],
                endpoint,
                payload,
                verb,
                headers,
            });
        };
    }

    httpAction(action) {
        const httpActionTemplate = {
            type: '',
            endpoint: null,
            verb: 'GET',
            payload: null,
            headers: [],
        };

        return {
            HTTP_ACTION: Object.assign({}, httpActionTemplate, action),
        };
    }
}
