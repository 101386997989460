import actions from './actions';
import createReducer from '../helpers/createReducer';
import BaseReducerHandlers from '../../classes/BaseReducerHandlers';
import actionAssignments from '../../classes/baseActionTypeAssignments';

class ReducerHanlders extends BaseReducerHandlers {
  constructor() {
    super();

    this.initialState = Object.assign({}, this.initialState, {
      color: {
        primary: '',
        seconday: '',
        info: '',
        warn: '',
        danger: '',
        active: ''
      },
      brand: {
        sidebarBrandLogoFull: '',
        sidebarBrandLogoMinimized: '',
      }
    });

  }

  // All Functions

  onRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  };
  onFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  };
}

const handlerInstance = new ReducerHanlders();

const reducerActions = actionAssignments(actions, handlerInstance, {
  // Custom Reducers:
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
