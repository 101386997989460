export default function actionAssignments(
  actions,
  handlerInstance,
  customAssignments
) {
  return {
    // Custom Assignments
    ...customAssignments,
  };
}
