import actions from './actions';
import createReducer from '../helpers/createReducer';
import BaseReducerHandlers from '../../classes/BaseReducerHandlers';
import actionAssignments from '../../classes/baseActionTypeAssignments';

class ReducerHanlders extends BaseReducerHandlers {
    constructor() {
        super();

        this.initialState = Object.assign({}, this.initialState, {
            title: '',
            content: '',
            onSuccess: (callback, props) => {
                callback(props);
                // console.log('OnSuccess triggered');
            },
            size: 'sm',
            show: false,
        });
    }

    // All Functions

    onShow = (state, action) => {
        const {
            title,
            content,
            onConfirm = state.onConfirm,
            size = state.size,
        } = action.payload;
        return {
            ...state,
            isLoading: false,
            error: null,
            title,
            content,
            onConfirm,
            size,
            show: true,
        };
    };
    onHide = (state, action) => {
        return {
            ...state,
            isLoading: false,
            show: false,
        };
    };
}

const handlerInstance = new ReducerHanlders();

const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:

    [actions.types.MODAL_SHOW_SET]: handlerInstance.onShow,
    [actions.types.MODAL_HIDE_SET]: handlerInstance.onHide,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
