import actions from './actions';
import createReducer from '../helpers/createReducer';
import CrudReducer from '../../classes/CrudReducer';
import actionAssignments from '../../classes/crudActionTypeAssignments';

class ReducerHanlders extends CrudReducer {
    constructor(actions) {
        super(actions);

        // Initial State gets copy/clones with the following additional configurations.
        this.initialState = Object.assign({}, this.initialState, {});
    }
}

const handlerInstance = new ReducerHanlders(actions);
const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:
    [actions.types.GET_ALL_CONVERSATIONS_FOR_PROJECT_SUCCESS]:
        handlerInstance.all_clear_success,
    [actions.types.GET_ALL_CONVERSATIONS_FOR_PROJECT_FAILED]:
        handlerInstance.all_failed,

    [actions.types.GET_UNREAD_CONVERSATIONS_FOR_PROJECT_SUCCESS]:
        handlerInstance.all_clear_success,
    [actions.types.GET_UNREAD_CONVERSATIONS_FOR_PROJECT_FAILED]:
        handlerInstance.all_failed,

    [actions.types.REQ_NEXT_ONE_CONVO_SUCCESS]: handlerInstance.all_success,
    [actions.types.REQ_NEXT_ONE_CONVO_FAILED]: handlerInstance.all_failed,

    // [actions.types.UPDATE_CONVERSAION_TAGS_SUCCESS]:
    // handlerInstance.one_success,
    [actions.types.UPDATE_CONVERSAION_TAGS_FAILED]: handlerInstance.one_failed,

    // Simplified
    [actions.types.GET_UNREAD_CONVERSATIONS_FOR_PROJECT_SIMPLE_SUCCESS]:
        handlerInstance.all_clear_success,
    [actions.types.GET_UNREAD_CONVERSATIONS_FOR_PROJECT_SIMPLE_FAILED]:
        handlerInstance.all_failed,
    // Custom Reducers:
    [actions.types.GET_ALL_INTERATIONS_FOR_CONVERSATION_SIMPLE_SUCCESS]:
        handlerInstance.one_success,
    [actions.types.GET_ALL_INTERATIONS_FOR_CONVERSATION_SIMPLE_FAILED]:
        handlerInstance.one_failed,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
