exports.baseURL =
    process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_COLOR === 'green'
            ? process.env.REACT_APP_DATA_BANK_BASE_URL_PRODUCTION_GREEN
            : process.env.REACT_APP_DATA_BANK_BASE_URL_PRODUCTION_BLUE
        : process.env.REACT_APP_DATA_BANK_BASE_URL_DEVELOPMENT;

exports.base_v3_api_url = 
    process.env.REACT_APP_STAGE === 'production' 
        ? process.env.REACT_APP_SERVICE_API_PRODUCTION 
            : process.env.REACT_APP_STAGE === 'chris' 
            ? process.env.REACT_APP_SERVICE_API_CHRIS 
            : ''
