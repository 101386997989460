// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('brands');

actions.registerTypes({
    type: `GET_COMPANY_BRAND`,
    // usable in reducer and components
    action: `reqCompanyBrand`,
    endpoint: (props) => {
        const { hostname } = props;
        return `/api/v1/orgs/getBrandByHostname/${hostname}`;
    },
    verb: 'POST',
});

export default actions;
