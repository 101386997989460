import Actions from '../../classes/StdActions';
const actions = new Actions('notifications');

actions.registerTypes({
    type: `NOTIFICATION_ADD`,
    action: `addNotification`,
});
actions.registerTypes({
    type: `NOTIFICATION_SHOW`,
    action: `showNotification`,
});
actions.registerTypes({
    type: `NOTIFICATIONS_CLEAR`,
    action: `clearNotification`,
});

export default actions;
