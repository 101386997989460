import Actions from '../../classes/StdActions';
const actions = new Actions('modal');

actions.registerTypes({
    type: `MODAL_SHOW`,
    action: `showModal`,
});

actions.registerTypes({
    type: `MODAL_HIDE`,
    action: `hideModal`,
});

actions.registerTypes({
    type: `TEST_FIRE`,
    action: `testFire`,
});

export default actions;
