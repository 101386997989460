// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';

const actions = new Actions('interaction_instances');

actions.registerTypes({
    type: `GET_ALL_INTERATIONS_FOR_CONVERSATION`,
    // usable in reducer and components
    action: `reqAllConvoInteractions`,
    endpoint: (props) => {
        const { project_id, convo_instance_id } = props;
        return `/api/v1/projects/${project_id}/conversations/${convo_instance_id}`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `GET_ALL_INTERATIONS_FOR_CONVERSATION_SIMPLE`,
    // usable in reducer and components
    action: `reqAllConvoInteractionsSimple`,
    endpoint: (props) => {
        const { project_id, convo_instance_id } = props;
        return `/api/v1/projects/${project_id}/conversations_simple/${convo_instance_id}`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `SEND_NEW_MSG_TO_CONVO`,
    // usable in reducer and components
    action: `reqSendNewMsg`,
    endpoint: (props) => {
        const { convo_instance_id } = props;
        return `/api/v1/interaction_instances/${convo_instance_id}/send_msg`;
    },
    verb: 'POST',
});

export default actions;
