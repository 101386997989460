import actions from './actions';
import createReducer from '../helpers/createReducer';
import StdReducer from '../../classes/StdReducer';
import actionAssignments from '../../classes/crudActionTypeAssignments';
import { decode } from '../_utils';

class ReducerHanlders extends StdReducer {
    constructor(actions) {
        super(actions);

        let decodedToken = this.auth_check(this.initialState, {
            token: localStorage.authToken,
        });

        // Initial State gets copy/clones with the following additional configurations.
        this.initialState = Object.assign({}, this.initialState, {
            access_log_id: null,
            tosConfirm: localStorage.tosConfirm ? true : false,
            user: decodedToken,
            hasRegistered: false,
            token: localStorage.authToken || null,
        });
    }

    is_expired = (token) => {
        const { exp } = token;
        const secondsRemaingBeforeExpire = exp - Date.now() / 1000;
        if (secondsRemaingBeforeExpire < 0) return true;
        return false;
    };

    auth_check = (state, action) => {
        const decodedToken = decode(action.token);
        if (this.is_expired(decodedToken)) {
            this.auth_clear(state);
            return null;
        } else {
            // All is good, token not expired, carry on.
            return decodedToken;
        }
    };

    auth_success = (state, action) => {
        // clear loading and error, update cache time, add items
        const { data: { token, access_log_id } = null } = action;

        // If Token is broken
        if (token === null)
            return {
                ...state,
                isLoading: false,
                error: null,
                loadedAt: Date.now(),
            };

        localStorage.authToken = token;

        return {
            ...state,
            user: decode(token) || null,
            token: token,
            access_log_id,
            isLoading: false,
            error: null,
            loadedAt: Date.now(1),
        };
    };

    register_success = (state, action) => {
        // clear loading and error, update cache time, add items
        return {
            ...state,
            hasRegistered: true,
        };
    };

    tos_confirm = (state, action) => {
        // clear loading and error, update cache time, add items
        localStorage.tosConfirm = true;
        return {
            ...state,
            tosConfirm: true,
        };
    };

    auth_clear = (state, action) => {
        // clear loading and error, update cache time, add items
        delete localStorage.authToken;
        delete localStorage.tosConfirm;

        return {
            ...state,
            user: {},
            token: null,
            tosConfirm: false,
            access_log_id: null,
            isLoading: false,
            error: null,
            loadedAt: 0,
        };
    };

    auth_failed = (state, action) => {
        // clear loading and error, update cache time, add items

        delete localStorage.authToken;
        const { payload: { response: { status } = {} } = {} } = action.payload;

        switch (status) {
            case 401:
                action.payload.message += ` - Unauthorized Access: Account has not been activated or incorrect credentials provided.`;
                break;
            case 500:
                action.payload.message += ` - Authentication temperarly unreachable.`;
                break;
            default:
                break;
        }

        return {
            ...state,
            user: {},
            token: null,
            isLoading: false,
            error: action.payload,
            loadedAt: Date.now(),
        };
    };
}

const handlerInstance = new ReducerHanlders(actions);

const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:

    [actions.types.REGISTER_SUCCESS]: handlerInstance.register_success,

    [actions.types.AUTH_SUCCESS]: handlerInstance.auth_success,
    [actions.types.AUTH_FAILED]: handlerInstance.auth_failed,

    [actions.types.TOS_CONFIRM_SUCCESS]: handlerInstance.tos_confirm,

    [actions.types.SIGN_OUT_SET]: handlerInstance.auth_clear,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
