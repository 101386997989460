// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('userOrgRoles');

// actions.registerTypes({
//     type: `STRIPE_SAVE_CARD`,
//     // usable in reducer and components
//     action: `reqSaveCard`,
//     endpoint: '/api/v1/stripe/attach-card',
//     verb: 'POST',
// });

// actions.registerTypes({
//     type: `STRIPE_REMOVE_CARD`,
//     // usable in reducer and components
//     action: `reqRemoveCard`,
//     endpoint: '/api/v1/stripe/reset-intent',
//     verb: 'POST',
// });

// actions.registerTypes({
//     type: `STRIPE_REMOVE_CARD`,
//     // usable in reducer and components
//     action: `reqRemoveCard`,
//     endpoint: '/api/v1/stripe/reset-intent',
//     verb: 'POST',
// });

actions.registerTypes({
    type: `UPDATE_USER_ORG_ROLES`,
    // usable in reducer and components
    action: `reqUpdateUserOrgRoles`,
    endpoint: (props) => {
        // const { org_id,user_id,role_id } = props;
        return `/api/v1/user_org_roles/update`;
    },
    verb: 'POST',
});

export default actions;
