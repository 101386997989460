// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('auth');

actions.registerTypes({
    type: `AUTH`,
    // usable in reducer and components
    action: `reqAuth`,
    endpoint: '/api/v1/auth',
    verb: 'POST',
});
actions.registerTypes({
    type: `SIGN_OUT`,
    action: `signOut`,
});
actions.registerTypes({
    type: `TOS_CONFIRM`,
    action: `tosConfirm`,
    endpoint: '/api/v1/auth/tosConfirm',
    verb: 'POST',
});

actions.registerTypes({
    type: `REGISTER`,
    // usable in reducer and components
    action: `reqRegister`,
    endpoint: '/api/v1/auth/register',
    verb: 'POST',
});

actions.registerTypes({
    type: `ORG_USER_REGISTER`,
    // usable in reducer and components
    action: `reqOrgUserRegister`,
    endpoint: '/api/v1/auth/org_user_register',
    verb: 'POST',
});

export default actions;
