// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('orgs');

actions.registerTypes({
    type: `STRIPE_SAVE_CARD`,
    // usable in reducer and components
    action: `reqSaveCard`,
    endpoint: '/api/v1/stripe/attach-card',
    verb: 'POST',
});

actions.registerTypes({
    type: `STRIPE_REMOVE_CARD`,
    // usable in reducer and components
    action: `reqRemoveCard`,
    endpoint: '/api/v1/stripe/reset-intent',
    verb: 'POST',
});

actions.registerTypes({
    type: `STRIPE_REMOVE_CARD`,
    // usable in reducer and components
    action: `reqRemoveCard`,
    endpoint: '/api/v1/stripe/reset-intent',
    verb: 'POST',
});

actions.registerTypes({
    type: `PUT_ORG`,
    // usable in reducer and components
    action: `reqPutOrg`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/orgs/${org_id}`;
    },
    verb: 'PUT',
});

actions.registerTypes({
    type: `GET_SUB_ORGS`,
    // usable in reducer and components
    action: `reqGetSubOrgs`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/orgs/${org_id}/sub_orgs`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `ADD_SUB_ORG_BALANCE`,
    // usable in reducer and components
    action: `reqAddSubOrgBalance`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/orgs/sub_org/${org_id}/add_balance`;
    },
    verb: 'POST',
});

actions.registerTypes({
    type: `UPDATE_SUB_ORG`,
    // usable in reducer and components
    action: `reqSubOrgUpdate`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/orgs/update_sub_org/${org_id}`;
    },
    verb: 'POST',
});

actions.registerTypes({
    type: `CREATE_SUB_ORG`,
    // usable in reducer and components
    action: `reqSubOrgCreate`,
    endpoint: (props) => {
        return `/api/v1/orgs/update_sub_org/create`;
    },
    verb: 'PUT',
});

actions.registerTypes({
    type: `MANUAL_CARD_DEBT`,
    // usable in reducer and components
    action: `reqManualCardDebt`,
    endpoint: (props) => {
        return `/api/v1/orgs/manual_card_charge/create`;
    },
    verb: 'POST',
});

export default actions;
