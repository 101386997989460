import Actions from '../../classes/StdActions';
const actions = new Actions('settings');

actions.registerTypes({
  type: `ASIDE_TOGGLE`,
  // usable in reducer and components
  action: `asideToggle`,
  // endpoint: "/api/v1/users"
});
actions.registerTypes({
  type: `ASIDE_SHOW`,
  action: `asideShowEnable`,
});
actions.registerTypes({
  type: `ASIDE_HIDE`,
  action: `asideShowDisable`,
});
actions.registerTypes({
  type: `DARK_MODE_TOGGLE`,
  action: `darkModeToggle`,
});
actions.registerTypes({
  type: `SIDEBAR_SHOW_TOGGLE`,
  action: `sidebarShowToggle`,
});

export default actions;
