// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('files');

actions.registerTypes({
    type: `S3_UPLOAD`,
    action: `reqUpload`,
    endpoint: '/api/v1/upload/s3',
    verb: 'POST',
});

actions.registerTypes({
    type: `GET_ORG_LOGO`,
    // usable in reducer and components
    action: `reqOrgLogos`,
    endpoint: (props) => {
        const { hostname } = props;
        return `/api/v1/files/getAllByHost/${hostname}`;
    },
    verb: 'GET',
});

export default actions;
