import actions from './actions';
import createReducer from '../helpers/createReducer';
import CrudReducer from '../../classes/CrudReducer';
import actionAssignments from '../../classes/crudActionTypeAssignments';

class ReducerHanlders extends CrudReducer {
    constructor(actions) {
        super(actions);

        // Initial State gets copy/clones with the following additional configurations.
        this.initialState = Object.assign({}, this.initialState, {});
    }
}

const handlerInstance = new ReducerHanlders(actions);
const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:

    [actions.types.GET_COMPANY_BRAND_SUCCESS]: handlerInstance.all_success,
    [actions.types.GET_COMPANY_BRAND_FAILED]: handlerInstance.all_failed,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
