export default function actionAssignments(
    actions,
    handlerInstance,
    customAssignments
) {
    return {
        // Custom Assignments
        [actions.types[`${actions.instanceType.toUpperCase()}_ALL_REQUESTED`]]:
            handlerInstance.all_requested,
        [actions.types[`${actions.instanceType.toUpperCase()}_ALL_SUCCESS`]]:
            handlerInstance.all_clear_success,
        [actions.types[`${actions.instanceType.toUpperCase()}_ALL_FAILED`]]:
            handlerInstance.all_failed,

        [actions.types[`${actions.instanceType.toUpperCase()}_ONE_REQUESTED`]]:
            handlerInstance.one_requested,
        [actions.types[`${actions.instanceType.toUpperCase()}_ONE_SUCCESS`]]:
            handlerInstance.one_success,
        [actions.types[`${actions.instanceType.toUpperCase()}_ONE_FAILED`]]:
            handlerInstance.one_failed,

        [actions.types[
            `${actions.instanceType.toUpperCase()}_UPDATE_ONE_REQUESTED`
        ]]: handlerInstance.one_requested,
        [actions.types[
            `${actions.instanceType.toUpperCase()}_UPDATE_ONE_SUCCESS`
        ]]: handlerInstance.one_success,
        [actions.types[
            `${actions.instanceType.toUpperCase()}_UPDATE_ONE_FAILED`
        ]]: handlerInstance.one_failed,

        [actions.types[
            `${actions.instanceType.toUpperCase()}_DISABLE_REQUEST`
        ]]: handlerInstance.one_requested,
        [actions.types[
            `${actions.instanceType.toUpperCase()}_DISABLE_SUCCESS`
        ]]: handlerInstance.delete_one_success,
        [actions.types[`${actions.instanceType.toUpperCase()}_DISABLE_FAILED`]]:
            handlerInstance.one_failed,

        ...customAssignments,
    };
}
