import { v4 as uuidv4 } from 'uuid';
import actions from './actions';
import createReducer from '../helpers/createReducer';
import StdReducer from '../../classes/StdReducer';
import actionAssignments from '../../classes/baseActionTypeAssignments';
import { removeIDFromArray } from '../_utils';

// import actions from './actions';
// import createReducer from '../helpers/createReducer';
// import StdReducer from '../../classes/StdReducer';
// import actionAssignments from '../../classes/crudActionTypeAssignments';
// import { decode } from '../_utils';

class ReducerHanlders extends StdReducer {
    constructor(actions) {
        super(actions);

        // Initial State gets copy/clones with the following additional configurations.
        this.initialState = Object.assign({}, this.initialState, {
            byID: {},
            allIDs: [],
            isloading: false,
            error: null,
        });
    }

    show_notification = (state, action) => {
        return {
            ...state,
            byID: {
                ...state.byID,
                [action.payload.id]: {
                    ...state.byID[action.payload.id],
                    shown: true,
                },
            },
            allIDs: [
                ...new Set([
                    ...removeIDFromArray(action.payload.id, state.allIDs),
                ]),
            ],
        };
    };
    clear_notification = (state, action) => {
        return {
            ...state,
            byID: {},
            allIDs: [],
        };
    };

    add_notification = (state, action) => {
        const {
            id = uuidv4(),
            position = 'bottom-right',
            autohide = 5000,
            // autohideValue = 5000,
            closeButton = true,
            fade = true,
            title = '',
            content = '',
            shown = false,
        } = action.payload;
        return {
            ...state,
            byID: {
                ...state.byID,
                [id]: {
                    isLoading: false,
                    error: null,
                    loadedAt: Date.now(),
                    data: {
                        id,
                        position,
                        autohide,
                        // autohideValue,
                        closeButton,
                        fade,
                        title,
                        content,
                        shown,
                    },
                },
            },
            allIDs: [...new Set([...state.allIDs, id])],
        };
    };
}

const handlerInstance = new ReducerHanlders(actions);

const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:

    [actions.types.NOTIFICATION_ADD_SET]: handlerInstance.add_notification,
    [actions.types.NOTIFICATION_SHOW_SET]: handlerInstance.show_notification,

    [actions.types.NOTIFICATIONS_CLEAR_SET]: handlerInstance.clear_notification,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
