import jwtDecode from 'jwt-decode';

export function aryFromObjectByID(obj) {
    return Object.keys(obj.byID).map((key) => obj.byID[key]);
}

export function aryFromObjectByProp(obj, prop) {
    return Object.keys(obj[prop]).map((key) => obj[prop][key]);
}

// turn an array into an object using the id of the items as the key
export function arrayToObject(array) {
    // use the reduce function to convert the array to an object
    return array.reduce(
        (object, item) => ({
            // keep the current object
            ...object,
            // add the item id as the key and the item as the value
            [item.id]: item,
        }),
        {}
    );
}

// given an object and id remove the key of the id from the object
export function removeIdFromObject(id, object) {
    // if we destructure the item we want to remove into a variable
    // and then use the spread operator to get the rest of the object
    // we have the object with out that one key

    const { [id]: removedItem, ...objectWithoutId } = object;
    return objectWithoutId;
}

// given an array of ids and id to remove, filter the array to remove all instances of that id
export function removeIDFromArray(id, array) {
    // if the itemId is not the id return true (don't remove)
    // if the itemId is the id return false (remove from array)
    return array.filter((itemId) => itemId !== id);
}

export function filterObjsBySubProps(objs, props) {
    let items;
    Object.entries(props).map((prop) => {
        return (items = Object.values(objs).filter((obj) => {
            // Ensure filtering only occurs on loaded data
            if (!obj.isLoading && obj.loadedAt) {
                return obj.data[prop[0]] === prop[1];
            } else {
                return false;
            }
        }));
    });

    return items;
}

export function objToParamPairs(where) {
    return Object.entries(where).map((key) => {
        return `${key[0]}=${key[1]}`;
    });
}

export function decode(token) {
    try {
        return jwtDecode(token);
    } catch (err) {
        return {};
    }
}
