// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('contact_lists');

actions.registerTypes({
    type: `GET_ALL_ORG_CONTACT_LISTS`,
    // usable in reducer and components
    action: `reqAllByOrg`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/contact_lists/by/${org_id}`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `GET_ALL_RELATED_ORG_CONTACT_LISTS`,
    action: `reqByRelatedOrg`,
    endpoint: `/api/v1/contact_lists/by/related_org/all`,
    verb: 'GET',
});

export default actions;
