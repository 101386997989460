import { removeIdFromObject, removeIDFromArray } from '../store/_utils';

export default class CrudReducers {
    constructor(actions) {
        this.col_id = actions.col_id;
        this.initialState = {
            byID: {},
            allIDs: [],
            // needed for cache state
            loadedAt: 0,
            // tracking if the state is loading
            isLoading: false,
            // any errors loading all the data
            error: null,
        };
    }

    // All Functions
    all_requested = (state, action) => {
        // set loading state and clear error
        return {
            ...state,
            isLoading: true,
            error: null,
        };
    };

    all_success = (state, action) => {
        return {
            ...state,
            isLoading: false,
            error: null,
            loadedAt: Date.now(),
            byID: {
                ...state.byID,
                ...action.data.reduce(
                    (items, item) => ({
                        // keep the current object
                        ...items,
                        // add the item id as the key and an item object for loading
                        [item[this.col_id]]: {
                            data: item,
                            isLoading: false,
                            loadedAt: Date.now(),
                            error: null,
                        },
                    }),
                    {}
                ),
            },
            allIDs: [
                ...new Set([
                    ...state.allIDs,
                    ...action.data.map((item) => item[this.col_id]),
                ]),
            ],
        };
    };

    all_clear_success = (state, action) => {
        return {
            ...state,
            isLoading: false,
            error: null,
            loadedAt: Date.now(),
            byID: {
                // ...state.byID, // remove history
                ...action.data.reduce(
                    (items, item) => ({
                        // keep the current object
                        ...items,
                        // add the item id as the key and an item object for loading
                        [item[this.col_id]]: {
                            data: item,
                            isLoading: false,
                            loadedAt: Date.now(),
                            error: null,
                        },
                    }),
                    {}
                ),
            },
            allIDs: [
                ...new Set([
                    // ...state.allIDs, // Removed history
                    ...action.data.map((item) => item[this.col_id]),
                ]),
            ],
        };
    };

    all_failed = (state, action) => {
        // clear loading and set error
        return {
            ...state,
            isLoading: false,
            error: action,
        };
    };

    // One Functions

    one_requested = (state, action) => {
        // set loading state and clear error

        return {
            ...state,
            byID: {
                ...state.byID,
                [action.payload[this.col_id]]: {
                    ...state.byID[action.payload[this.col_id]],
                    isLoading: true,
                    error: null,
                },
            },
        };
    };

    one_success = (state, action) => {
        // clear loading and error, update cache time, add items

        return {
            ...state,
            byID: {
                ...state.byID,
                [action.payload[this.col_id]]: {
                    // [action.payload[this.col_id]]: {
                    isLoading: false,
                    error: null,
                    loadedAt: Date.now(),
                    data: action.data,
                },
            },
            allIDs: [
                ...new Set([...state.allIDs, action.payload[this.col_id]]),
            ],
        };
    };

    one_data_success = (state, action) => {
        // clear loading and error, update cache time, add items
        return {
            ...state,
            byID: {
                ...state.byID,
                [action.data.id]: {
                    // [action.payload[this.col_id]]: {
                    isLoading: false,
                    error: null,
                    loadedAt: Date.now(),
                    data: action.data,
                },
            },
            allIDs: [...new Set([...state.allIDs, action.data.id])],
        };
    };

    one_no_payload_id_success = (state, action) => {
        // clear loading and error, update cache time, add items

        return {
            ...state,
            byID: {
                ...state.byID,
                [action.data[this.col_id]]: {
                    // [action.payload[this.col_id]]: {
                    isLoading: false,
                    error: null,
                    loadedAt: Date.now(),
                    data: action.data,
                },
            },
            allIDs: [...new Set([...state.allIDs, action.data[this.col_id]])],
        };
    };

    one_failed = (state, action) => {
        // clear loading and set error
        return {
            ...state,
            byID: {
                ...state.byID,
                [action.payload[this.col_id]]: {
                    ...state.byID[action.payload[this.col_id]],
                    isLoading: false,
                    error: action.err,
                },
            },
        };
    };

    // Delete One Functions
    delete_one_success = (state, action) => {
        // clear loading and error, update cache time, add items
        return {
            ...state,
            byID: removeIdFromObject(action.payload[this.col_id], state.byID),
            allIDs: [
                ...new Set([
                    ...removeIDFromArray(
                        action.payload[this.col_id],
                        state.allIDs
                    ),
                ]),
            ],
        };
    };

    // TODO: make complex deletes better for things like cart
    // Delete One Functions
    delete_where_success = (state, action) => {
        // clear loading and error, update cache time, add items
        return {
            ...state,
            byID: [
                ...state.byID,
                ([action.payload.prop] = removeIdFromObject(
                    action.payload.id,
                    state.byID[action.payload.prop]
                )),
            ],

            // allIDs: [
            //   ...new Set([...removeIDFromArray(action.payload.id, state.allIDs)]),
            // ],
        };
    };
}
