// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('files');

actions.registerTypes({
    type: `GET_ALL_ORG_MEDIA_FILES`,
    // usable in reducer and components
    action: `reqOrgMediaFiles`,
    endpoint: `/api/v1/media_files/by/org`,
    verb: 'GET',
});

actions.registerTypes({
    type: `GET_ALL_RELATED_ORG_MEDIA_FILES`,
    action: `reqMediaFilesByRelatedOrg`,
    endpoint: `/api/v1/media_files/by/related_org`,
    verb: 'GET',
});

export default actions;
