// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';

const actions = new Actions('convo_instances');

actions.registerTypes({
    type: `GET_ALL_CONVERSATIONS_FOR_PROJECT`,
    // usable in reducer and components
    action: `reqAllProjectConvos`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/projects/${project_id}/conversations`;
    },
    verb: 'GET',
});
actions.registerTypes({
    type: `GET_UNREAD_CONVERSATIONS_FOR_PROJECT`,
    // usable in reducer and components
    action: `reqAllUnreadProjectConvos`,
    endpoint: (props) => {
        const { project_id, type = 'replies' } = props;
        return `/api/v1/projects/${project_id}/conversations/unread/${type}`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `UPDATE_CONVERSAION_TAGS`,
    // usable in reducer and components
    action: `reqUpdateConversationTags`,
    endpoint: (props) => {
        const { convo_instance_id } = props;
        return `/api/v1/convo_instances/${convo_instance_id}/tags`;
    },
    verb: 'POST',
});

actions.registerTypes({
    type: `MARK_INTERACTIONS_AS_READ_FOR_CONVO`,
    // usable in reducer and components
    action: `reqMarkConvoAsRead`,
    endpoint: (props) => {
        const { convo_instance_id } = props;
        return `/api/v1/interaction_instances/${convo_instance_id}/mark_read`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `REQ_NEXT_ONE_CONVO`,
    // usable in reducer and components
    action: `reqNextOne`,
    endpoint: (props) => {
        const { project_id } = props;
        return `/api/v1/convo_instances/${project_id}/next`;
    },
    verb: 'POST',
});

export default actions;

// Simplified

actions.registerTypes({
    type: `GET_UNREAD_CONVERSATIONS_FOR_PROJECT_SIMPLE`,
    // usable in reducer and components
    action: `reqAllUnreadProjectConvosSimple`,
    endpoint: (props) => {
        const { project_id, type = 'replies' } = props;
        return `/api/v1/projects/${project_id}/conversations_simple/unread/${type}`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `GET_ALL_INTERATIONS_FOR_CONVERSATION_SIMPLE`,
    // usable in reducer and components
    action: `reqAllConvoInteractionsSimple`,
    endpoint: (props) => {
        const { project_id, convo_instance_id } = props;
        return `/api/v1/projects/${project_id}/conversations_simple/${convo_instance_id}`;
    },
    verb: 'GET',
});
