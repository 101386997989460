import actions from './actions';
import createReducer from '../helpers/createReducer';
import CrudReducer from '../../classes/CrudReducer';
import actionAssignments from '../../classes/crudActionTypeAssignments';

class ReducerHanlders extends CrudReducer {
    constructor(actions) {
        super(actions);

        // Initial State gets copy/clones with the following additional configurations.
        this.initialState = Object.assign({}, this.initialState, {});
    }
}

const handlerInstance = new ReducerHanlders(actions);
const reducerActions = actionAssignments(actions, handlerInstance, {
    // Custom Reducers:
    [actions.types.PROJECTS_UPSERT_RELATED_SUCCESS]:
        handlerInstance.one_success,

    [actions.types.PUT_PROJECT_SUCCESS]: handlerInstance.one_success,
    [actions.types.PUT_PROJECT_FAILED]: handlerInstance.one_failed,

    [actions.types.REMOVE_DID_FROM_PROJECT_SUCCESS]:
        handlerInstance.one_success,
    [actions.types.REMOVE_DID_FROM_PROJECT_FAILED]: handlerInstance.one_failed,

    [actions.types.ADD_DID_TO_PROJECT_SUCCESS]: handlerInstance.one_success,
    [actions.types.ADD_DID_TO_PROJECT_FAILED]: handlerInstance.one_failed,

    [actions.types.PROJECT_SEND_ONE_SMS_SUCCESS]: handlerInstance.one_success,
    [actions.types.PROJECT_SEND_ONE_SMS_FAILED]: handlerInstance.one_failed,

    [actions.types.CREATE_NEW_PROJECT_SUCCESS]:
        handlerInstance.one_no_payload_id_success,
    [actions.types.CREATE_NEW_PROJECT_FAILED]: handlerInstance.one_failed,

    [actions.types.PROJECT_SEND_SMS_SUCCESS]: handlerInstance.one_success,
    [actions.types.PROJECT_SEND_SMS_FAILED]: handlerInstance.one_failed,

    [actions.types.UNSET_PROJECT_SUCCESS]: handlerInstance.one_success,
    [actions.types.UNSET_PROJECT_FAILED]: handlerInstance.one_failed,

    [actions.types.RESET_ARCHIVED_PROJECT_SUCCESS]: handlerInstance.one_success,
    [actions.types.RESET_ARCHIVED_PROJECT_FAILED]: handlerInstance.one_failed,

    [actions.types.ALL_ARCHIVED_PROJECTS_SUCCESS]:
        handlerInstance.all_clear_success,
    [actions.types.ALL_ARCHIVED_PROJECTS_FAILED]: handlerInstance.all_failed,

    [actions.types.PROJECT_COUNTS_SUCCESS]: handlerInstance.one_success,
    [actions.types.PROJECT_COUNTS_FAILED]: handlerInstance.one_failed,

    [actions.types.ARCHIVE_PROJECT_SUCCESS]: handlerInstance.all_clear_success,
    [actions.types.ARCHIVE_PROJECT_FAILED]: handlerInstance.all_failed,
});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
