// import Actions from '../../classes/StdActions';
import Actions from '../../classes/CrudActions';
const actions = new Actions('users');

actions.registerTypes({
    type: `DISABLE_USER`,
    // usable in reducer and components
    action: `disableUser`,
    endpoint: (props) => {
        // const { user_id } = props;
        // return `/api/v1/users/${user_id}`;
        return `/api/v1/users/`;
    },
    verb: 'DELETE',
});

actions.registerTypes({
    type: `UPSERT_USER`,
    // usable in reducer and components
    action: `upsertUser`,
    endpoint: (props) => {
        return `/api/v1/users/upsert`;
        // return `/api/v1/users/`;
    },
    verb: 'POST',
});

actions.registerTypes({
    type: `ORG_USERS`,
    // usable in reducer and components
    action: `reqAllOrgUsers`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/orgs/${org_id}/users`;
        // return `/api/v1/users/`;
    },
    verb: 'GET',
});

actions.registerTypes({
    type: `USER_DISABLE`,
    // usable in reducer and components
    action: `disableStateOne`,
    endpoint: (props) => {
        const { user_id } = props;
        return `/api/v1/users/${user_id}/disable`;
        // return `/api/v1/users/`;
    },
    verb: 'GET',
});
actions.registerTypes({
    type: `USER_ENABLE`,
    // usable in reducer and components
    action: `enableStateOne`,
    endpoint: (props) => {
        const { user_id } = props;
        return `/api/v1/users/${user_id}/enable`;
        // return `/api/v1/users/`;
    },
    verb: 'GET',
});
actions.registerTypes({
    type: `ORG_USERS_DISABLE`,
    // usable in reducer and components
    action: `disableStateOrgUsers`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/users/${org_id}/disable/org`;
        // return `/api/v1/users/`;
    },
    verb: 'GET',
});
actions.registerTypes({
    type: `ORG_USERS_ENABLE`,
    // usable in reducer and components
    action: `enableStateOrgUsers`,
    endpoint: (props) => {
        const { org_id } = props;
        return `/api/v1/users/${org_id}/enable/org`;
        // return `/api/v1/users/`;
    },
    verb: 'GET',
});

export default actions;
