import actions from './actions';
import createReducer from '../helpers/createReducer';
import BaseReducerHandlers from '../../classes/BaseReducerHandlers';
import actionAssignments from '../../classes/baseActionTypeAssignments';

class ReducerHanlders extends BaseReducerHandlers {
  constructor() {
    super();

    // Initial State gets copy/clones with the following additional configurations.
    this.initialState = Object.assign({}, this.initialState, {
      sidebarShow: window.innerWidth < 768 ? false : true,
      asideShow: false,
      darkMode: false,
    });
  }

  // All Functions
  aside_show_enable = (state, action) => {
    return {
      ...state,
      asideShow: true,
      isLoading: false,
      error: null,
    };
  };
  aside_show_disable = (state, action) => {
    return {
      ...state,
      asideShow: false,
      isLoading: false,
      error: null,
    };
  };
  dark_mode_toggle = (state, action) => {
    return {
      ...state,
      darkMode: !state.darkMode,
      isLoading: false,
      error: null,
    };
  };
  sidebar_show_toggle = (state, action) => {
    return {
      ...state,
      sidebarShow: !state.sidebarShow,
      isLoading: false,
      error: null,
    };
  };
  onRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  };
  onFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  };
}

const handlerInstance = new ReducerHanlders();

const reducerActions = actionAssignments(actions, handlerInstance, {
  // Custom Reducers:
  [actions.types.ASIDE_TOGGLE_REQUESTED]: handlerInstance.onRequest,
  [actions.types.ASIDE_TOGGLE_SET]: handlerInstance.sidebar_show_toggle,
  [actions.types.ASIDE_TOGGLE_FAILED]: handlerInstance.onFailure,

  [actions.types.ASIDE_HIDE_REQUESTED]: handlerInstance.onRequest,
  [actions.types.ASIDE_HIDE_SET]: handlerInstance.aside_show_disable,
  [actions.types.ASIDE_HIDE_FAILED]: handlerInstance.onFailure,

  [actions.types.ASIDE_SHOW_REQUESTED]: handlerInstance.onRequest,
  [actions.types.ASIDE_SHOW_SET]: handlerInstance.aside_show_enable,
  [actions.types.ASIDE_SHOW_FAILED]: handlerInstance.onFailure,

  [actions.types.DARK_MODE_TOGGLE_REQUESTED]: handlerInstance.onRequest,
  [actions.types.DARK_MODE_TOGGLE_SET]: handlerInstance.dark_mode_toggle,
  [actions.types.DARK_MODE_TOGGLE_FAILED]: handlerInstance.onFailure,

  [actions.types.SIDEBAR_SHOW_TOGGLE_REQUESTED]: handlerInstance.onRequest,
  [actions.types.SIDEBAR_SHOW_TOGGLE_SET]: handlerInstance.sidebar_show_toggle,
  [actions.types.SIDEBAR_SHOW_TOGGLE_FAILED]: handlerInstance.onFailure,

});

// Pass all prepared options to createReducer
export default createReducer(handlerInstance.initialState, reducerActions);
