import { createStore, compose, combineReducers, applyMiddleware } from 'redux';

// middleware for making actions asynchronous
import thunkMiddleware from 'redux-thunk';

// will log to console all the actions that are run
import { createLogger } from 'redux-logger';

import API from '../API';

// pull our containerized reducers
import area_codes from './area_codes/reducer';
import brand from './brand/reducer';
import theme from './theme/reducer';
import settings from './settings/reducer';
import users from './users/reducer';
import auth from './auth/reducer';
import notifications from './notifications/reducer';
import modal from './modal/reducer';
import states from './states/reducer';
import orgs from './orgs/reducer';
import projects from './projects/reducer';
import contactLists from './contactLists/reducer';
import files from './files/reducer';
import roles from './roles/reducer';
import stripe from './stripe/reducer';
import dids from './dids/reducer';
import convo_instances from './convo_instances/reducer';
import interaction_instances from './interaction_instances/reducer';
import contacts from './contacts/reducer';
import userOrgRoles from './userOrgRoles/reducer';
import media_files from './media_files/reducer';
import custom_fields from './custom_fields/reducer';

//--- Middleware
//--- Actions and action creator
const HTTP_ACTION = 'HTTP_ACTION';

const httpMiddleware = (store) => (next) => (action) => {
    if (!action) return false; // not fully tested
    if (action[HTTP_ACTION]) {
        const actionInfo = action[HTTP_ACTION];
        const { payload: { upl } = {} } = actionInfo;
        if (upl) actionInfo.files = upl; // console.log('xxxxx', upl);

        if (!actionInfo.endpoint) {
            next({
                type: actionInfo.type + '_SET',
                payload: actionInfo.payload,
            });
        } else {
            // Allows for dynamic endpoints and static endpoints defined in actions (look at disableUser action)
            if (typeof actionInfo.endpoint === 'function')
                actionInfo.endpoint = actionInfo.endpoint(actionInfo.payload);
            next({
                type: actionInfo.type + '_REQUESTED',
                payload: {
                    ...actionInfo.payload,
                    // files: actionInfo.payload.files,
                },
                headers: {
                    ...actionInfo.headers,
                    'content-type': 'multipart/form-data',
                },
            });
            API({
                method: actionInfo.verb,
                url: actionInfo.endpoint,
                headers: {
                    ...actionInfo.headers,
                    'Access-Control-Allow-Origin': '*',
                },
                data: actionInfo.payload,
            })
                .then((reqData) =>
                    next({
                        type: actionInfo.type + '_SUCCESS',
                        payload: actionInfo.payload,
                        data: reqData.data,
                    })
                )
                .catch((error) =>
                    next({
                        type: actionInfo.type + '_FAILED',
                        payload: error,
                    })
                );
        }
    } else {
        return next(action);
    }
};

const middleware = applyMiddleware(
    thunkMiddleware,
    httpMiddleware,
    createLogger()
);

const allReducers = combineReducers({
    auth,
    area_codes,
    brand,
    theme,
    settings,
    users,
    notifications,
    modal,
    states,
    orgs,
    projects,
    contactLists,
    files,
    roles,
    stripe,
    dids,
    convo_instances,
    interaction_instances,
    contacts,
    userOrgRoles,
    media_files,
    custom_fields,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create a redux store using the combined reducer and middleware functions
export const store = createStore(allReducers, composeEnhancer(middleware));
